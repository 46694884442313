import React, { FC, Fragment } from "react";
import Ad from "../Modules/Ad";
const Demo2: FC<{}> = () => {
  return (
    <Fragment>
      <h3>rich media with Vertical Sticky</h3>
      
      <div id="content-container">
        <div className="grid-container">
          <div className="grid-item a">A</div>
          <div className="grid-item b">B</div>
          <div className="grid-item c">C</div>
          <div className="grid-item d">
            <Ad placementId="64b1174fd0d63654629edcf7" />
          </div>
          <div className="grid-item e"><Ad placementId="64b1170caa7f8f16d379c6a6" /></div>
        </div>
       
      </div>
      <div className="ad-container">
        <Ad placementId="64b1178aaa7f8f16d379c6a8" />
      </div>

      <Ad placementId="64b11763d0d63654629edcf9" richMedia={true} />
    </Fragment>
  );
};
export default Demo2;
