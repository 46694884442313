/**
 * using use componentDidMount & componentWillUnmount
 */
import React, { Component, RefObject, createRef } from "react";

type AdProps = {
  placementId: string;
  hybridBanner?: boolean;
  richMedia?: boolean;
};

export default class Ad extends Component<AdProps> {
  private adRef: RefObject<HTMLDivElement> = createRef();

  componentDidMount() {
    window.__vm_add = window.__vm_add || [];
    window.__vm_add.push(this.adRef.current);
    console.log("Venatus : vm_add : ", this.adRef.current);
  }

  componentWillUnmount() {
    if (this.adRef.current?.getAttribute("ad-type") === "rich-media") {
      window.__vm_remove_category = ["richmedia_all"];

      console.log("Venatus : vm_remove_category ");
    } else {
      window.__vm_remove = window.__vm_remove || [];
      window.__vm_remove.push(this.adRef.current);

      console.log("Venatus : vm_remove : ", this.adRef.current);
    }

  }

  render() {
    const { placementId, hybridBanner, richMedia } = this.props;
    if (!placementId) {
      return null;
    }
    return (
      <>
        {richMedia ? (
        <div
          ref={this.adRef}
          className="vm-placement"
          data-id={placementId}
          ad-type="rich-media"
          style={{ display: "none" }}
        ></div>
      ) : hybridBanner ? (
        <div
          ref={this.adRef}
          className="vm-placement"
          data-id={placementId}
          ad-type="hybrid-banner"
          data-display-type="hybrid-banner"
        ></div>
      ) : (
        <div
          ref={this.adRef}
          className="vm-placement"
          data-id={placementId}
          ad-type="desktop-ad"
        ></div>
      )}
      </>
    );
  }
}