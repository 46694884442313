import React, { useState } from "react";
import "./styles/style.scss";

import Tabs from "./Components/Tabs";
// Tabs Components
import Demo1page from "./Components/Demo2";
import Demo2page from "./Components/Demo1";
import Demo3page from "./Components/Demo3";
import Footer from "./Components/Footer";

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Demo1",
    index: 1,
    Component: Demo1page
  },
  {
    label: "Demo2",
    index: 2,
    Component: Demo2page
  },
  {
    label: "Demo3",
    index: 3,
    Component: Demo3page
  }
];

export default function App() {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  return (
    <div className="wrapper">
      <div className="desk-navbar">
        <div className="container navbar">
        <a href="https://venatus.com/" className="brand w-nav-brand">
                <img src="https://uploads-ssl.webflow.com/625ecf3842ad2419f8758d34/625fef1a502b2e5532c582d2_venus-logo.svg" loading="lazy" className="logo"></img>
            </a>

            <nav role="navigation">
                <a href="https://showcase.venatusmedia.com/" className="navbar-link w-nav-link">Home</a>
                <a href="https://www.venatus.com/contact" className="navbar-link w-nav-link">Contact</a>
                <a href="https://docs.google.com/presentation/d/1Agilje40iN6MZVAtq6t-9yEyoAxRsTiYZDPwdhm6UwA/edit#slide=id.gb77e77b0e4_0_41" className="navbar-link w-nav-link">Specs</a>
            </nav>
      </div>
      </div>
      
      <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} />
      <Footer></Footer>
    </div>
  );
}
