import React, { FC, Fragment } from "react";
import Ad from "../Modules/Ad";
const Demo3: FC<{}> = () => {
  return (
    <Fragment>
      <h3> footer </h3>
  
      <div id="content-container">
        <div className="grid-container">
          <div className="grid-item a"><Ad placementId="64b1170caa7f8f16d379c6a6" /></div>
          <div className="grid-item b"><Ad placementId="64b1174fd0d63654629edcf7" /></div>
          <div className="grid-item c">C</div>
          <div className="grid-item d">
            D
          </div>
          <div className="grid-item e">E</div>
        </div>
       
      </div>
      <div className="ad-container">
        <Ad placementId="64b1178aaa7f8f16d379c6a8" />
      </div>
      <Ad placementId="64b11c6cd0d63654629edd01" richMedia={true} />
    </Fragment>
  );
};
export default Demo3;
